import WebServiceRequest from '../Api/WebServiceRequest'

class SeminarPresentRequestCreate extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('SeminarPeresentRequest/Create')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}
class SeminarPresentRequestGet extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('SeminarPeresentRequest/Get')
  }
  setParams(data){
    super.setRequestParam(data)
  }
}
export {
  SeminarPresentRequestCreate,
  SeminarPresentRequestGet
};
