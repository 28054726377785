<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="numbers.length > 0">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <span> تعداد کل : </span>
              <span class="mx-1">{{ totalCount }}</span>
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="count"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector mx-50 w-50"
              />
              <label>تعداد</label>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start"
            >
              <b-button
                  variant="danger"
                  class="w-100"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="sortBy = ''"
              >
                حذف فیلتر‌های دسته‌بندی
              </b-button>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start ml-auto"
            >
              <b-button
                  variant="success"
                  class="w-100"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="downloadFile"
              >
                دانلود اکسل لیست
              </b-button>
            </b-col>

            <!-- Search -->
            <!--            <b-col-->
            <!--                cols="12"-->
            <!--                md="6"-->
            <!--            >-->
            <!--              <div class="d-flex align-items-center justify-content-end">-->
            <!--                <b-form-input-->
            <!--                    v-model="searchCommand"-->
            <!--                    class="d-inline-block mr-1"-->
            <!--                    placeholder="جستجوی شماره موبایل ..."-->
            <!--                />-->
            <!--              </div>-->
            <!--            </b-col>-->
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="numbers"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            :sort-by.sync="sortBy"
            empty-text="موردی موجود نیست!"
        >
          <!-- Column: createDate -->
          <template #cell(createDate)="data">
            <span>
              {{data.item.createdAt.slice(11,16)}}
              -
              {{createJalaliDate(data.item.createdAt.slice(0,10))}}
            </span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <!--    <b-modal-->
    <!--        id="modal-delete"-->
    <!--        centered-->
    <!--        ok-title="حذف"-->
    <!--        cancelTitle="انصراف"-->
    <!--        @ok="deleteCoupon(deleteItem)"-->
    <!--    >-->
    <!--      <span>حذف شود؟</span>-->
    <!--    </b-modal>-->
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CouponGetAllRequest} from "@/libs/Api/Coupon";
import {CouponDeleteRequest} from "@/libs/Api/Coupon";
import Helper from "@/libs/Helper";
import {NumberGetAllRequest} from "@/libs/Api/Number";
import {ConsultantRequestGet} from "@/libs/Api/ConsultantRequest";
import {SeminarGetRequest} from "@/libs/Api/Seminar";
import {SeminarPresentRequestGet} from "@/libs/Api/SeminarPresentRequest";
import axiosIns from "@/libs/axios";
import {saveAs} from 'file-saver';
import {getUserData} from "@/auth/utils";

export default {
  name: "List",
  title:"لیست درخواست‌ها - پنل ادمین مکس",
  data(){
    return{
      coupons:null,
      numbers:[],
      showOverlay:false,
      totalCount:null,
      addNewCategory:false,
      currentPage:1,
      deleteItem:null,
      perPage:10,
      pageNumber:1,
      count:50,
      searchCommand:'',
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'firstName',label:'نام',sortable: true},
        { key: 'lastName',label:'نام خانوادگی',sortable: true},
        { key: 'nationalCode',label:'کد ملی',sortable: true},
        { key: 'phoneNumber',label:'شماره موبایل',sortable: true},
        // { key: 'createdAt',label:'تاریخ ثبت'},
      ],
      defaultCreateData:{
        isDeleted:false,
        productCategoryId:0,
        parentId:0,
        name:''
      },
      sortBy: '',
    }
  },
  async created(){
    await this.getAllRequests(this.pageNumber,this.count,this.searchCommand)
  },
  methods:{
    async getAllRequests(pageNumber,count,searchCommand){
      let  _this = this;

      _this.showOverlay = true;

      let data = {
        page:pageNumber,
        size:count,
      }

      let seminarPresentRequestGet = new SeminarPresentRequestGet(_this);
      seminarPresentRequestGet.setParams(data);
      await seminarPresentRequestGet.fetch(function (content){
        _this.numbers = content.data.data;
        _this.totalCount = content.data.totalCount;
        _this.showOverlay = false;
      },function (error){
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async deleteCoupon(param){
      let _this = this;

      let couponDeleteRequest = new CouponDeleteRequest(_this);
      couponDeleteRequest.setId(param);
      await couponDeleteRequest.fetch(function (content){
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `کوپن حذف شد.`,
          },
        })
        _this.getAllRequests(_this.pageNumber,_this.count,_this.searchCommand)
      },function (error){
        console.log(error)
      })
    },
    async downloadFile() {
      let _this = this;
      _this.showOverlay = true;
      let userToken = getUserData().data.token;

      await axiosIns.post(`SeminarPeresentRequest/GenerateExcel?seminarId=1`, null, {
        responseType: 'blob',
        'Authorization': userToken,
      }).then(resp => {
        _this.showOverlay = false;
        saveAs(resp.data, 'لیست درخواست ها.xlsx');
      })
          .catch((error) => {
            _this.showOverlay = false;
            console.log(error);
          });
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch:{
    currentPage:function (nv,ov) {
      this.getAllRequests(nv,this.count,this.searchCommand);
    },
    searchCommand:function (nv,ov) {
      this.getAllRequests(this.currentPage,this.count,nv);
    },
    count:function (nv,ov) {
      this.getAllRequests(this.currentPage,nv,this.searchCommand);
    },
  },
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
}
</script>

<style scoped>

</style>